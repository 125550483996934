import { CbhFeatureFlag, useCbhFlag } from "@src/appV2/FeatureFlags";

export function useOnCallShiftsConfig() {
  return useCbhFlag(CbhFeatureFlag.PRICING_ON_CALL_SHIFTS, {
    defaultValue: {
      noPlacementBonusPayHours: 2,
      onCallAreas: [],
    },
  });
}
