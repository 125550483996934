import { isDefined } from "@clipboard-health/util-ts";
import {
  MANDATORY_BREAK_DURATION_IN_MINUTES,
  MIN_SHIFT_LENGTH_FOR_MANDATORY_BREAK_IN_MINUTES,
} from "@src/appV2/redesign/Shift/constants";
import { ExtraWorkedTimeType, type TimeRange } from "@src/appV2/Shifts/Shift/types";
import { differenceInMinutes, parseISO } from "date-fns";

export function doesShiftHaveWorkedBreak(params: {
  clockInOut: TimeRange;
  lunchInOut: TimeRange;
}): boolean {
  const { clockInOut, lunchInOut } = params;

  const workDurationInMinutes =
    isDefined(clockInOut.start) && isDefined(clockInOut.end)
      ? differenceInMinutes(parseISO(clockInOut.end), parseISO(clockInOut.start))
      : 0;
  const breakDurationInMinutes =
    isDefined(lunchInOut.start) && isDefined(lunchInOut.end)
      ? differenceInMinutes(parseISO(lunchInOut.end), parseISO(lunchInOut.start))
      : 0;

  return (
    workDurationInMinutes > MIN_SHIFT_LENGTH_FOR_MANDATORY_BREAK_IN_MINUTES &&
    breakDurationInMinutes < MANDATORY_BREAK_DURATION_IN_MINUTES
  );
}

export function getExtraWorkedTime(
  clockInOut: TimeRange,
  lunchInOut: TimeRange | undefined,
  breakAuthorizationReason: string | undefined
): Array<{ type: ExtraWorkedTimeType; authorization: { reason: string } }> | undefined {
  const hasWorkedBreak =
    isDefined(breakAuthorizationReason) &&
    isDefined(lunchInOut) &&
    doesShiftHaveWorkedBreak({ clockInOut, lunchInOut });

  if (!hasWorkedBreak) {
    return undefined;
  }

  const authorization = { reason: breakAuthorizationReason };
  return [{ type: ExtraWorkedTimeType.BREAK, authorization }];
}
