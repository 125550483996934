import { put } from "@src/appV2/api";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { type TimeRange } from "@src/appV2/Shifts/Shift/types";
import { getExtraWorkedTime } from "@src/appV2/Shifts/Shift/utils";
import { useMutation } from "@tanstack/react-query";
import { z } from "zod";

export interface SubmitShiftTimeSheetParameters {
  clockInOut: TimeRange;
  lunchInOut?: TimeRange;
  shiftId: string;
  signedBy: string;
  breakAuthorizationReason?: string;
  location?: {
    latitude: string;
    longitude: string;
  };
  timecard: {
    url: string;
    filename: string;
    fileStorageFileKey: string;
  };
}

export const DEFAULT_TIMESHEET_LOCATION = {
  latitude: "12",
  longitude: "34",
};

export function useSubmitShiftTimeSheet() {
  return useMutation({
    mutationFn: async (data: SubmitShiftTimeSheetParameters) => {
      const {
        clockInOut,
        lunchInOut,
        shiftId,
        signedBy,
        breakAuthorizationReason,
        location = DEFAULT_TIMESHEET_LOCATION,
        timecard,
      } = data;
      const extraWorkedTime = getExtraWorkedTime(clockInOut, lunchInOut, breakAuthorizationReason);
      await put({
        url: `/v2/shifts/timecard/${shiftId}`,
        data: {
          timecard: [timecard],
          location: [location.longitude, location.latitude],
          submitClockInOut: clockInOut,
          submitLunchInOut: lunchInOut,
          digitallySignedBy: signedBy,
          extraWorkedTime,
        },
        responseSchema: z.unknown(),
      });
      return timecard;
    },
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.ERROR_SUBMITTING_SHIFT_TIME_SHEET,
    },
  });
}
