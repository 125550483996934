import { isDefined } from "@clipboard-health/util-ts";
import { useGetRecentColleagues } from "@src/appV2/Agents/api/useGetRecentColleagues";
import {
  FriendRequestStatus,
  useGetWorkerFriendRequests,
} from "@src/appV2/Agents/api/useGetWorkerFriendRequests";
import { useGetWorkerFriends } from "@src/appV2/Agents/api/useGetWorkerFriends";
import { useGetShiftSlots } from "@src/appV2/OpenShifts/api/useGetShiftSlots";
import { type Colleague, type Shift } from "@src/appV2/Shifts/Shift/types";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";

interface UseGetShiftColleaguesParams {
  shift?: Shift;
  isShiftCompleted: boolean;
}

interface UseGetShiftColleaguesResult {
  isLoading: boolean;
  data: Colleague[];
}

/**
 * FIXME
 *
 * This hook needs a simplification. It probably doesn't need to know a few things
 * like if the agent is hidden as a colleague or that the shift is completed. For
 * example, those are used to check if we return colleagues or not. Probably, what
 * we should do is return the list of colleagues and leave filtering to the callers
 * depending on their specific requirements. Ideally, what this should be is a simple
 * call to the BE, to retrieve a specific resource, colleagues. This would allow us
 * to replace this whole logic by a single call to `useGetQuery`.
 *
 * https://linear.app/clipboardhealth/issue/FEF-495/refactor-usegetshiftcolleagues
 */
// eslint-disable-next-line sonarjs/cognitive-complexity
export function useGetShiftColleagues(
  params: UseGetShiftColleaguesParams
): UseGetShiftColleaguesResult {
  const { shift, isShiftCompleted } = params;

  const worker = useDefinedWorker();

  const { isLoading: isLoadingRecentColleagues, data: recentColleaguesData } =
    useGetRecentColleagues();

  const { isLoading: isLoadingAgentFriends, data: workerFriendsData } = useGetWorkerFriends();
  const { isLoading: isLoadingSentFriendRequests, data: sentFriendRequestsData } =
    useGetWorkerFriendRequests(FriendRequestStatus.SENT);

  const recentColleagues = recentColleaguesData ?? [];
  const workerFriends = workerFriendsData ?? [];
  const workerFriendRequests = sentFriendRequestsData ?? [];

  const {
    isSuccess: isSuccessShiftSlots,
    isLoading: isLoadingShiftSlots,
    data: shiftSlots,
  } = useGetShiftSlots(
    {
      workplaceSlots: [
        {
          workplaceUserId: shift?.facility.userId ?? "",
          range: { start: shift?.start ?? "", end: shift?.end ?? "" },
        },
      ],
    },
    {
      enabled: isDefined(shift) && isDefined(shift.facility.userId),
    }
  );

  if (
    isLoadingAgentFriends ||
    isLoadingShiftSlots ||
    isLoadingRecentColleagues ||
    isLoadingSentFriendRequests
  ) {
    return { isLoading: true, data: [] };
  }

  const { hideAsColleagueOnShifts: isHiddenAsColleague, userId } = worker;

  if (isHiddenAsColleague && (isShiftCompleted || workerFriends.length === 0)) {
    return { isLoading: false, data: [] };
  }

  const shiftSlot = isSuccessShiftSlots ? shiftSlots[0] : undefined;
  const agentsWhoBookedShiftAtFacility = shiftSlot?.bookedBy ?? [];

  const colleagues = agentsWhoBookedShiftAtFacility
    .map((shiftColleague) => {
      const isFriend = workerFriends.some((friend) => friend.workerId === shiftColleague.userId);
      const isFriendRequestSent = workerFriendRequests.some(
        (friendRequest) => friendRequest.workerId === shiftColleague.userId
      );

      const recentlyWorkedWithColleague =
        isShiftCompleted || isHiddenAsColleague
          ? undefined
          : recentColleagues.find(
              ({ colleagueUserId }) => shiftColleague.userId === colleagueUserId
            );

      const colleague: Colleague = {
        colleagueUserId: shiftColleague.userId,
        colleagueName: shiftColleague.name,
        qualification: shiftColleague.qualification,
        hasProfilePic: shiftColleague.hasProfilePic,
        shiftStart: recentlyWorkedWithColleague?.shiftStart,
        facilityName: recentlyWorkedWithColleague?.facilityName,
        shiftName: recentlyWorkedWithColleague?.shiftName,
        isFriendRequestSent,
        isFriend,
      };

      return colleague;
    })
    // Filter out the agent themselves
    .filter((colleague) => colleague.colleagueUserId !== userId)
    // Show only friends if the shift is not completed and the agent is hidden as a colleague
    .filter((colleague) => isShiftCompleted || !isHiddenAsColleague || colleague.isFriend)
    .sort((colleague1, colleague2) => {
      if (colleague1.isFriend !== colleague2.isFriend) {
        return colleague1.isFriend ? -1 : 1;
      }

      if (colleague1.isFriendRequestSent !== colleague2.isFriendRequestSent) {
        return colleague1.isFriendRequestSent ? 1 : -1;
      }

      if (
        !isShiftCompleted &&
        isDefined(recentColleagues) &&
        colleague1.shiftStart !== colleague2.shiftStart
      ) {
        return colleague1.shiftStart ? -1 : 1;
      }

      if (colleague1.hasProfilePic !== colleague2.hasProfilePic) {
        return colleague1.hasProfilePic ? -1 : 1;
      }

      return 0;
    });

  return { isLoading: false, data: colleagues };
}
