import { useFacilityProfile } from "@src/appV2/Facilities/api/useFacilityProfile";
import { CbhFeatureFlag, useCbhFlag } from "@src/appV2/FeatureFlags";

import { NAME_PAGE_BOLDED_MESSAGE } from "./constants";

export interface FeedbackOption {
  value: string;
  type?: "POSITIVE" | "NEGATIVE";
}

export const defaultFeedbackQuestion = {
  firstQuestion: {
    description: "Did this professional complete their assigned tasks today when possible?",
    subText: undefined,
  },
  mainQuestion: {
    description: "How did this professional do after arriving at your facility today?",
    negativeOptions: ["Could put in some extra effort", "Professionalism could be better"],
    otherOptions: ["Other", "Skip"],
    positiveOptions: ["Excellent attitude", "Great with patients"],
    subText: undefined,
  },
};

export function useRevampWorkplaceSigningFlow(workplaceId: string) {
  const {
    enabled,
    mainQuestion = defaultFeedbackQuestion.mainQuestion,
    firstQuestion,
    namePageBoldedMessage = NAME_PAGE_BOLDED_MESSAGE,
    qrCodeSignatureBeforeClockOutIsEnabled = false,
  } = useCbhFlag(CbhFeatureFlag.ROLLOUT_FEEDBACK_FOR_QUALITY_EXPERIMENT, {
    defaultValue: {
      enabled: false,
      namePageBoldedMessage: NAME_PAGE_BOLDED_MESSAGE,
      qrCodeSignatureBeforeClockOutIsEnabled: false,
    },
  });

  const { data: facilityProfile } = useFacilityProfile({
    workplaceId,
    projection: "type qrCodeForClockOutIsEnabled",
  });
  const { qrCodeForClockOutIsEnabled = false } = facilityProfile ?? {};
  const { otherOptions = [], positiveOptions = [], negativeOptions = [] } = mainQuestion ?? {};
  const mainQuestionOptions: FeedbackOption[] = [
    ...positiveOptions.map((value) => ({ value, type: "POSITIVE" as const })),
    ...negativeOptions.map((value) => ({ value, type: "NEGATIVE" as const })),
    ...otherOptions.map((value) => ({ value })),
  ];

  return {
    useRevampedWorkplaceSignoutIsEnabled: enabled,
    firstQuestion: {
      description: firstQuestion?.description,
      subText: firstQuestion?.subText,
    },
    mainQuestion: {
      description: mainQuestion?.description,
      options: mainQuestionOptions,
      subText: mainQuestion?.subText,
    },
    useQRCodeSignoutFlow: qrCodeForClockOutIsEnabled,
    namePageBoldedMessage,
    qrCodeSignatureBeforeClockOutIsEnabled:
      qrCodeSignatureBeforeClockOutIsEnabled && qrCodeForClockOutIsEnabled,
  };
}
