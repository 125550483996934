import { isDefined } from "@clipboard-health/util-ts";

import { useOnCallShiftsConfig } from "./useOnCallShiftsConfig";

export function useCheckIsOnCallWorkplace() {
  const { onCallAreas } = useOnCallShiftsConfig();
  const onCallWorkplaceIds = new Set(onCallAreas.map((area) => area.parentWorkplaceId));

  return (workplaceId?: string) => isDefined(workplaceId) && onCallWorkplaceIds.has(workplaceId);
}
