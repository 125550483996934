import { post } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { useMutation, type UseMutationResult } from "@tanstack/react-query";
import { z } from "zod";

export const CreateTimeclockComplianceProofUploadUrlEntityType =
  "timeclock-compliance-proof-upload-url";
export enum TimeclockComplianceClockAction {
  CLOCK_IN = "CLOCK_IN",
  CLOCK_OUT = "CLOCK_OUT",
}
export enum TimeclockComplianceResourceType {
  MP4 = "mp4",
}

export interface UseCreateTimeclockComplianceProofUploadUrlProps {
  shiftId: string;
  clockAction: TimeclockComplianceClockAction;
  resourceType: TimeclockComplianceResourceType;
}

export const postCreateTimeclockComplianceProofUploadUrlRequestSchema = z.object({
  data: z.object({
    type: z.literal(CreateTimeclockComplianceProofUploadUrlEntityType),
    attributes: z.object({
      clockAction: z.nativeEnum(TimeclockComplianceClockAction),
      resourceType: z.nativeEnum(TimeclockComplianceResourceType),
    }),
  }),
});

export const postCreateTimeclockComplianceProofUploadUrlResponseSchema = z.object({
  data: z.object({
    id: z.string(),
    type: z.literal(CreateTimeclockComplianceProofUploadUrlEntityType),
    attributes: z.object({
      bucketName: z.string(),
      fileKey: z.string(),
      uploadUrl: z.string(),
      resourceType: z.nativeEnum(TimeclockComplianceResourceType),
      clockAction: z.nativeEnum(TimeclockComplianceClockAction),
    }),
  }),
});

export type PostCreateTimeclockComplianceProofUploadUrlRequest = z.infer<
  typeof postCreateTimeclockComplianceProofUploadUrlRequestSchema
>;
export type PostCreateTimeclockComplianceProofUploadUrlResponse = z.infer<
  typeof postCreateTimeclockComplianceProofUploadUrlResponseSchema
>;

export function useCreateTimeclockComplianceProofUploadUrl(): UseMutationResult<
  PostCreateTimeclockComplianceProofUploadUrlResponse,
  unknown,
  UseCreateTimeclockComplianceProofUploadUrlProps
> {
  return useMutation({
    mutationFn: async (props: UseCreateTimeclockComplianceProofUploadUrlProps) => {
      const { shiftId, clockAction, resourceType } = props;

      const response = await post({
        url: `${environmentConfig.REACT_APP_BASE_API_URL}/shifts/${shiftId}/timeclock-compliance-proof-upload-url`,
        data: {
          data: {
            type: CreateTimeclockComplianceProofUploadUrlEntityType,
            attributes: {
              clockAction,
              resourceType,
            },
          },
        },
        requestSchema: postCreateTimeclockComplianceProofUploadUrlRequestSchema,
        responseSchema: postCreateTimeclockComplianceProofUploadUrlResponseSchema,
      });

      return response.data;
    },
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.POST_MANDATORY_BREAK_POLICY_ACKNOWLEDGEMENT_FAILURE,
    },
  });
}
