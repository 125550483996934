import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import {
  useMutation,
  type UseMutationOptions,
  type UseMutationResult,
} from "@tanstack/react-query";

import { get } from "../../../api";
import { type GetShiftResponse, getShiftResponseSchema } from "./useGetShift";

export function useGetShiftMutation(
  shiftId: string,
  options: UseMutationOptions<GetShiftResponse, unknown, unknown> = {}
): UseMutationResult<GetShiftResponse> {
  return useMutation({
    mutationFn: async () => {
      const response = await get({
        url: `${environmentConfig.REACT_APP_BASE_API_URL}/v1/shifts/myShifts/details/${shiftId}`,
        responseSchema: getShiftResponseSchema,
      });
      return response.data;
    },
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.GET_SHIFT_FAILURE,
    },
    ...options,
  });
}
